import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlogPost from '../components/BlogPost'

const Post = ({ data }) => {
  const post = data.selectedJobQuery

  return (
    <Layout>
      <Helmet title={`${post.title} | Work With Visa`} />
      <BlogPost post={post} />
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query($guid: String!) {
    selectedJobQuery: jobsJson(guid: { eq: $guid }) {
      guid
      title
      content
      applyUrl
      link
      categories
      companyLogo
      isoDate
      author {name: a10_name}
      country
    }
  }
`
import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Card, CardContent, CardActions, Button} from '@material-ui/core';

import { BooleanParam, useQueryParam } from "use-query-params"
import CookieManager from "./cookie"
import { Close } from '@material-ui/icons';
import StartsIcon from "@material-ui/icons/Stars"
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  postJobButtonReUse: {
    background: "#ff424d",
    textTransform: "none",
    margin: "4px",
    color: "white"
  },
  hiddenDiv: {
    display: 'none'
  }
}));

const keyToEnable = "pagePromo"; // used in cookie and queryparams
const keyToDisable = "disablePagePromo"; // used in cookie and queryparams

//this logic is moved here, only becuase this is used when people try to buy ads!
const cm = typeof window === 'object' ? CookieManager() : undefined;

const PagePromo = ({jobID, title}) => {
  const classes = useStyles();
  const adTitle = title ? title : "Your partner in relocation";

  const [adPlacement, setAdPlacement] = useQueryParam(keyToEnable, BooleanParam)
  const [disableAdPlacement, setDisableAdPlacement] = useQueryParam(keyToDisable, BooleanParam)


  const isInCookie = () => {
    return cm && cm.getCookie(keyToEnable);
  }

  const showAdPlacement = adPlacement || isInCookie();
  if (disableAdPlacement) {
    cm.setCookie(keyToEnable, adPlacement, 0); //reset
  } else if (showAdPlacement) {
    cm.setCookie(keyToEnable, adPlacement, 1/24/60*1); //10 min
  }

  //const [visible, setVisible] = useState(showAdPlacement) // true is the inital state

  useEffect( () => {
    const adPlacementDiv = document && document.getElementById("pagePromoDiv");

    if (disableAdPlacement) {
      if (adPlacementDiv){
        adPlacementDiv.style.display = 'none';
      }
    } else if (showAdPlacement && !disableAdPlacement) {
      if (adPlacementDiv){
        adPlacementDiv.style.display = 'block';
      }
    }
  }, []); // no listener value specified, so it reacts as componentDidMount


  return (
    <div id='pagePromoDiv' className={classes.hiddenDiv}>
      <Helmet>
        <script src="https://js.stripe.com/v3"></script>
      </Helmet>
      <Card style={{background: '#e8ebf7', }} raised>
        <CardContent>
        <Chip
          icon={<StartsIcon />}
          label="Promote this job!"
          color="primary"
          variant="outlined"
        />
        </CardContent>
        <CardContent>
            <strong>Choose your promotion option: </strong><br />
            <ul>
                <li>
                    <Button color="white" className={classes.postJobButtonReUse} onClick={
                        event => {
                            event.stopPropagation()
                            var stripe = Stripe('pk_live_51IZdTzLcjCBIaiDWrV2lnZuiUUG32UqJdePjYMtcuGDLBYZVue0XJU7iy9iYMBWvMjaDuN4MdTEHSA2eYCRixPCk00oTGnipld');

                            /*
                            * When the customer clicks on the button, redirect
                            * them to Checkout.
                            */
                            stripe.redirectToCheckout({
                                lineItems: [{price: 'price_1JJvq7LcjCBIaiDWnLsCLdCO', quantity: 1}],
                                mode: 'payment',
                                /*
                                * Do not rely on the redirect to the successUrl for fulfilling
                                * purchases, customers may not always reach the success_url after
                                * a successful payment.
                                * Instead use one of the strategies described in
                                * https://stripe.com/docs/payments/checkout/fulfill-orders
                                */
                                successUrl: 'https://workwithvisa.com/verified_partner/successful_payment',
                                cancelUrl: 'https://workwithvisa.com/verified_partner/cancelled_payment',
                                clientReferenceId: jobID
                            })
                            .then(function (result) {
                                if (result.error) {
                                    /*
                                    * If `redirectToCheckout` fails due to a browser or network
                                    * error, display the localized error message to your customer.
                                    */
                                    alert("An error has happened:\n\n"+jobID+"\n\nPlease try again!\nIf the issue still exists, please contact us at info@workwithvisa.com!");
                                }
                            });
                        }
                        }>
                            Main page promotion!
                    </Button>
                    <i>(One-time payment, <strong>99 EUR for 28 days</strong>, "Promotion" section on the main page)</i>
                </li>
                <li>
                    <Button color="white" className={classes.postJobButtonReUse} onClick={
                        event => {
                            event.stopPropagation()
                            var stripe = Stripe('pk_live_51IZdTzLcjCBIaiDWrV2lnZuiUUG32UqJdePjYMtcuGDLBYZVue0XJU7iy9iYMBWvMjaDuN4MdTEHSA2eYCRixPCk00oTGnipld');
                            /*
                            * When the customer clicks on the button, redirect
                            * them to Checkout.
                            */
                            stripe.redirectToCheckout({
                                lineItems: [{price: 'price_1JJvrrLcjCBIaiDWOnTgVbPJ', quantity: 1}],
                                mode: 'payment',
                                /*
                                * Do not rely on the redirect to the successUrl for fulfilling
                                * purchases, customers may not always reach the success_url after
                                * a successful payment.
                                * Instead use one of the strategies described in
                                * https://stripe.com/docs/payments/checkout/fulfill-orders
                                */
                                successUrl: 'https://workwithvisa.com/verified_partner/successful_payment',
                                cancelUrl: 'https://workwithvisa.com/verified_partner/cancelled_payment',
                                clientReferenceId: jobID
                            })
                            .then(function (result) {
                                if (result.error) {
                                /*
                                * If `redirectToCheckout` fails due to a browser or network
                                * error, display the localized error message to your customer.
                                */
                                alert("An error has happened:\n\n"+jobID+"\n\nPlease try again!\nIf the issue still exists, please contact us at info@workwithvisa.com!");
                                }
                            });
                        }
                        }>
                            Country highlight!
                    </Button>
                    <i>(One-time payment: <strong>199 EUR for 7 days</strong>, visible on top of every job offer from the chosen country (exclusively) + on the country summary page + shared 2x/week on social media)</i>
                </li>
            </ul>
        </CardContent>
        <CardActions disableSpacing style={{justifyContent: 'flex-end', }}>
        <Button
          className={classes.postJobButtonReUse}
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<Close />}
          onClick={
            event => {
                event.stopPropagation();
                if (cm) {
                  cm.setCookie(keyToEnable, adPlacement, 0); //reset
                }

                const adPlacementDiv = document && document.getElementById("pagePromoDiv");
                if (adPlacementDiv){
                  adPlacementDiv.style.display = 'none';
                }
                //setVisible(false); // trigger re-render --- not working
                navigate(`?${keyToDisable}=1`);
            }
          }
        >
          Hide
        </Button>
        </CardActions>
      </Card>
      <br></br>
    </div>
  );
}

export default PagePromo


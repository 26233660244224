import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Card, CardContent} from '@material-ui/core';
import AdIconFilled from './adicon-filled';

const useStyles = makeStyles((theme) => ({
  ad: {
    backgroundImage: 'url("/assets/ad320x50.png")', 
    backgroundRepeat: 'round', 
    width: '100%',
    height: '50px',
    padding: '0',
    maxHeight: '90px',
    maxWidth: '320px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'url("/assets/ad728x90.png")',
      height: '90px',
      maxWidth: '728px'
    },
  },

  hiddenDiv: {
    display: 'block'
  }
}));


const AdContainerWhite = ({ children, title }) => { 
  const classes = useStyles();
  const adTitle = title ? title : "";

  return (
  <div id='adContainerDiv' className={classes.hiddenDiv}>
    <Card>
      <CardContent>
      <Chip style={{color: '#6574c4', border: '1px solid #6574c4', }}
          icon={<AdIconFilled style={{color: '#6574c4', paddingLeft: '3px' }} background='white'/>}
          label={adTitle}
          variant="outlined"
        />
      </CardContent>
      <CardContent style={{flexFlow: 'column', display: 'flex'}}>
      {children}
      </CardContent>
    </Card>
    <br />
  </div> 
  );
}

export default AdContainerWhite

import React from 'react'
import styled from '@emotion/styled'

import { Link } from "gatsby"
import SEO from './seo'
import Autolinker from 'autolinker'

import { Card, CardContent, Chip, Typography, Button, Box, Tooltip, Hidden } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning';
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff"
import kebabCase from "lodash/kebabCase"

import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import NotificationInLine from './notification-inline'
import scrollTo from 'gatsby-plugin-smoothscroll'
import AdPlacement from './ad-placement'
import PagePromo from './page-promo'
import AdIconFilled from './adicon-filled'
import PagePromoButton from './page-promo-button'

import AdSense from 'react-adsense';
import AdContainerWhite from '../components/ad-container-white';

const BlogPostStyle = styled.div`
  h1.title {
    margin: 0;
    font-size: calc(1.5vw + 5px);
    line-height: calc(1.5vw + 10px);
    font-weight: 500;
    a {
      text-decoration: none;
      color: black;
    }

    @media only screen and (max-width: 1024px) {
      font-size: calc(3vw + 5px);
      line-height: calc(3vw + 10px);
    }
  }

  .jobcontent {
    #br + br {
    #  display:none;
    #}

    p + br {
      display:none;
    }

    #p > br {
    #  display:none;
    #}

    ul + br {
      display:none;
    }

    ul > br {
      display:none;
    }

    li + br {
      display:none;
    }

    li > br {
      display:none;
    }

    #strong + br {
    #  display:none;
    #}

    #p > strong {
    #  background: yellow;
    #}

  }

  .imgSmall {
    max-width: 30%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h3.postDate {
    font-weight: 500;

    @media only screen and (max-width: 1024px) {
      font-size: calc(2vw + 5px);
      line-height: calc(2vw + 10px);
    }
  }

  .tagList {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .jobOldWarning {
    background: #FFFDE7;
  }

  .applyButton {
    background: #ff424d;
  }

  .noLink {
    text-decoration: none;
  }

  padding: 16px

`

const BlogPost = ({ post, companyLogo }) => {
  if (post.categories == undefined) {
    post.categories = [];
  }
  const extendedCategories = post.categories.concat(['visa', 'work', 'abroad']);
  const linkedText = Autolinker.link(post.content);
  const imageUrl = post.companyLogo ? `/logo/${post.companyLogo}` : "";

  const postedDays = Math.round((Date.now() - Date.parse(post.isoDate))/(24*3600*1000));
  const postedText = postedDays === 0 ? "Today" : `${postedDays} day${
    postedDays === 1 ? "" : "s" 
  } ago`

  var finalApplyUrl = post.applyUrl ? post.applyUrl : post.link;
  const queryParamPattern = new RegExp(/\?.+=.*/g);
  finalApplyUrl = finalApplyUrl + (queryParamPattern.test(finalApplyUrl) ? "&" : "?") + "utm_source=workwithvisa.com&ref=workwithvisa.com";

  const promoSwitchUrl = typeof window === 'object' ? window.location.href + "?pagePromo=1" : "";

  return (
    <>
    <Hidden xsDown>
       <NotificationInLine />
    </Hidden>
    <br />
    <PagePromo title={post.title} jobID={post.guid} />
    <Card>
      <SEO 
        title={post.title} 
        keywords={extendedCategories} 
        description={`Work with Visa as ${post.title}`} 
        isPost={true} 
        image={imageUrl}
      />
      <CardContent>
        <BlogPostStyle>
          <h1 className="title">
            {post.title}
          </h1>
          <div className="tagList">
            {post.categories.map(tag => (
              <Link key={tag} to={`/tags/${kebabCase(tag)}`}>
                <Chip key={`chip-${tag}`} label={`#${tag}`} size='small' clickable={true} />
              </Link>
            ))}
            <Link key={post.author.name} to={`/companies/${kebabCase(post.author.name)}`}>
              <Chip key={`chip-${post.author.name}`} label={`#${post.author.name}`} size='small' clickable={true} />
            </Link>
            <Link key={post.country} to={`/countries/${kebabCase(post.country)}`}>
              <Chip key={`chip-${post.country}`} label={`#${post.country}`} size='small' clickable={true} />
            </Link>
          </div>
          <h3 className="postDate">
            {`Posted:  ${postedText}`}
          </h3>
          {
            postedDays > 30 ?
            <CardContent className="jobOldWarning">
              <Typography color="textSecondary">
              This job post is more than 30 days old and probably filled.<br />Please consider visiting the company's website for the latest vacancies! <WarningIcon fontSize="small" />
              </Typography>
            </CardContent>
            : <></>
          }

          <div className="imgSmall" >
              <img className="imgSmall" alt={post.author.name} src={imageUrl} />
          </div>
          <h2 className="apply">
            <a href={`${finalApplyUrl}`} className="noLink">
              <Button variant="contained" color="secondary" className="applyButton" onClick={e => {
                // To stop the page reloading
                e.preventDefault();
                // Lets track that custom click
                trackCustomEvent({
                  category: "Apply",
                  action: "Click top",
                  label: post.guid + "::" + post.country + "::" + post.title ,
                });
                window.location.href = finalApplyUrl;
              }}>
                Apply now!
              </Button>
            </a>
            <Box component="span" m={1} />
            <Tooltip title="Take a look on our curated list of agencies!" placement="top">
              <Link to={`#relocation`} className="noLink">
                <Button variant="contained" color="secondary"  variant="outlined" onClick={e => {
                    // To stop the page reloading
                    e.preventDefault();
                    scrollTo("a#relocation")
                  }}>
                  <FlightTakeoffIcon style={{paddingRight: '3px' }}/>
                  Ready to relocate?
                </Button>
              </Link>
            </Tooltip>
            <Box component="span" m={1} />
            <Tooltip title="Are you the owner of this job? Promote it!" placement="top">
              <Link to={promoSwitchUrl} className="noLink">
                <PagePromoButton />
              </Link>
            </Tooltip>
          </h2>
          <div className="jobcontent" dangerouslySetInnerHTML={{ __html: linkedText }} />
          <h2 className="apply">
            <a href={`${finalApplyUrl}`} className="noLink">
              <Button variant="contained" color="secondary" className="applyButton" onClick={e => {
                // To stop the page reloading
                e.preventDefault();
                // Lets track that custom click
                trackCustomEvent({
                  category: "Apply",
                  action: "Click bottom",
                  label: post.guid + "::" + post.country + "::" + post.title ,
                });
                window.location.href = finalApplyUrl;
              }}>
                Apply now!
              </Button>
            </a>
          </h2>
        </BlogPostStyle>
      </CardContent>
    </Card>
    <><br/></>
    <a name="relocation" id="relocation"></a>
    <AdPlacement />
    <AdContainerWhite title='An ad to keep our services up and running. Sorry.'>
      <AdSense.Google
          client='ca-pub-9152097609554171'
          slot='5290184966'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
      />
    </AdContainerWhite>
      <Card>
        <CardContent>
          <Chip
            icon={<FlightTakeoffIcon />}
            label="Relocation and visa support agencies"
            color="primary"
            variant="outlined"
          />
        </CardContent>
        <CardContent>
          <b>Have you found your dream job already? Ready to relocate but don't know where to start?</b><br /><br />
          <ol>
          <li>Ask your dream company about the support they provide. </li><br />
          <li>Do they provide what you need? Do you miss anything? <b>Negotiate in your contract to use the service of one of our listed relocation and visa support agencies!</b></li><br />
          <li>Congratulations, you are ready to move! Prepare yourself and wait for the big day!</li>
          </ol>
          <br />
          <i>(Did anything go wrong? Is the company not willing to provide visa sponsorship? Is the job listing wrong? We really apologize for the mistake! Please let us know and we delist the offer and/or the company from our website!)</i>
          <br /><br />
          <b>Take a look at our curated list of relocation and visa support agencies</b> that can help you kick-off your new journey!
          <br /><br />
          <a href={`/relocations`} style={{textDecoration: 'none', }}>
            <div style={{textAlign: 'center', }}>
            <Button variant="contained" color="secondary"  variant="outlined">
              <FlightTakeoffIcon />
              Find an agent
            </Button>
            </div>
          </a>
        </CardContent>
      </Card>
    </>
  )
}

export default BlogPost


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Card, CardContent, CardActions, IconButton } from '@material-ui/core';
import RedditIcon from '@material-ui/icons/Reddit';
import FacebookIcon from '@material-ui/icons/Facebook';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    color: 'white', 
    justifyContent: 'center', 
    flexGrow: '1',
    [theme.breakpoints.up('sm')]: {
      flexGrow: '0',
    },
  },
}));

export default function NotificationInLine() {
  const classes = useStyles();

  return (
    <Card style={{background: '#5c6bc0', flexFlow: 'wrap', display: 'flex' }}>
      <CardContent style={{ flexGrow: '1'}}>
      <Chip style={{color: 'white', border: '1px solid white', }}
          icon={<NotificationsIcon style={{color: 'white', }}/>}
          label="Notifications - the latest job offers!"
          variant="outlined"
        />
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        <a href="https://twitter.com/workwithvisa">
          <IconButton aria-label="Twitter">
            <TwitterIcon fontSize="small" style={{color: 'white', }}/>
          </IconButton>
        </a>
        <a href="https://www.linkedin.com/showcase/workwithvisa">
          <IconButton aria-label="LinkedIn" style={{color: 'white', }}>
            <LinkedInIcon fontSize="small" />
          </IconButton>
        </a>
        <a href="https://t.me/workwithvisa">
          <IconButton aria-label="Telegram" style={{color: 'white', }}>
            <TelegramIcon fontSize="small" />
          </IconButton>
        </a>
        <a href="https://facebook.com/workwithvisa">
          <IconButton aria-label="Facebook" style={{color: 'white', }}>
            <FacebookIcon fontSize="small" />
          </IconButton>
        </a>
        <a href="https://reddit.com/r/workwithvisa">
          <IconButton aria-label="Reddit" style={{color: 'white', }}>
            <RedditIcon fontSize="small" />
          </IconButton>
        </a>
      </CardActions>
    </Card>
  );
}

import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';

import { BooleanParam, useQueryParam } from "use-query-params"
import CookieManager from "./cookie"
import AdIconFilled from "./adicon-filled";
import { Button } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  hiddenDiv: {
    display: 'none'
  }
}));

const keyToEnable = "pagePromo"; // used in cookie and queryparams
const keyToDisable = "disablePagePromo"; // used in cookie and queryparams

//this logic is moved here, only becuase this is used when people try to buy ads!
const cm = typeof window === 'object' ? CookieManager() : undefined;

const PagePromoButton = ({jobID, title}) => {
  const classes = useStyles();
  const adTitle = title ? title : "Your partner in relocation";

  const [adPlacement, setAdPlacement] = useQueryParam(keyToEnable, BooleanParam)
  const [disableAdPlacement, setDisableAdPlacement] = useQueryParam(keyToDisable, BooleanParam)


  const isInCookie = () => {
    return cm && cm.getCookie(keyToEnable);
  }

  const showAdPlacement = adPlacement || isInCookie();
  if (disableAdPlacement) {
    //cm.setCookie(keyToEnable, adPlacement, 0); //reset
  } else if (showAdPlacement) {
    //cm.setCookie(keyToEnable, adPlacement, 1/24/60*1); //10 min
  }

  //const [visible, setVisible] = useState(showAdPlacement) // true is the inital state

  useEffect( () => {
    const adPlacementDiv = document && document.getElementById("pagePromoSpan");

    if (showAdPlacement) {
      if (adPlacementDiv){
        adPlacementDiv.style.display = 'none';
      }
    } else if (!showAdPlacement || disableAdPlacement) {
      if (adPlacementDiv){
        adPlacementDiv.style.display = 'inherit';
      }
    }
  }, []); // no listener value specified, so it reacts as componentDidMount


  return (
    <span id='pagePromoSpan' className={classes.hiddenDiv}>
      <Button variant="contained" color="secondary"  variant="outlined" style={{background: "#e8ebf7", color: "#5c6bc0", border: "1px solid #5c6bc0"}}
      onClick={e => {
        // To stop the page reloading
        if (typeof window === 'object' && (!showAdPlacement || disableAdPlacement)) {
          e.preventDefault();
          window.location.href = window.location.href + "?pagePromo=1"
        }
      }}>
        <AdIconFilled style={{paddingRight: '3px', color: "#5c6bc0" }} background="#e8ebf7"/> 
        Promote this job!
      </Button>
    </span>
  );
}

export default PagePromoButton

